import React from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 15,
    marginBottom: 15, 
    color: theme.palette.primary.main,
    '& p': {
      fontWeight: 'bold'
    }
  }
}))

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box mt={5}>
        <Typography align="center">
          {/* Voglio {new Date().getFullYear()} */}
           Dante - ??/05/{new Date().getFullYear()}
        </Typography>
      </Box>
    </footer>
  )
}

export default Footer;
