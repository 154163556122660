import React from 'react';

import Header from '../../containers/Header';

const PromoEmbed = () => {

  return (
    <>
      <Header />
      {window.location.href = "https://nayamaemarcelo.typeform.com/to/YWu6LytH"}
    </>
  )
}

export default PromoEmbed;