import React, { useState, useEffect } from 'react';
import firebase from "../../firebaseconfig";
import "firebase/auth";
import "firebase/firestore";

import Button from '../../components/Button';
import Input from '../../components/Input';
import Header from '../../containers/Header';
import Image from '../../components/Image/Image';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Box, Fab, makeStyles, AppBar, Toolbar, List, Divider, ListItem, ListItemAvatar, ListItemText, Dialog, Container, Snackbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CheckIcon from '@material-ui/icons/Check';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  appBarModal: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'transparent',
    boxShadow: "none"
  },
  grow: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    top: -26,
    left: 0,
    right: 180,
    margin: '0 auto',
  },
  addButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  saveButton: {
    position: 'absolute',
    zIndex: 1,
    top: -26,
    left: 0,
    right: -180,
    margin: '0 auto',
  },
  banner: {
    marginLeft: 0,
    marginRight: 0,
    '& img': {
      width: '100%',
      display: 'block',
    },
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    '& a': {
      textDecoration: 'none'
    }
  },
  listImage: {
    marginLeft: 0,
    marginRight: '15px',
    '& img': {
      width: '100px',
      display: 'block'
    }
  },
  listDivider: {
    marginLeft: 0,
    marginRight: 0,
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    },
    '& :visited': {
      textDecoration: 'none'
    }
  },
  buttonGroup: {
    display: 'flex',
    '& >div': {
      marginRight: 20,
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListDetail = ({ listItemDetail }) => {
  const classes = useStyles();

  const [listDetail, setListDetail] = useState({});
  const [userLogged, setUserLogged] = useState(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const [isInputBannerActive, setIsInputBannerActive] = useState(false);
  const [isInputProductActive, setIsInputProductActive] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [isBuyItem, setIsBuyItem] = useState(false);
  const [isShowAddress, setIsShowAddress] = useState(false);
  const isUpToBuy = true;

  const [titleValue, setTitleValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [timeValue, setTimeValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [arriveAddressValue, setArriveAddressValue] = useState('');
  const [buyerShowAddressValue, setBuyerShowAddressValue] = useState('');
  const [callLinkValue, setCallLinkValue] = useState('');
  const [items, setItems] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState();
  const [buyerValue, setBuyerValue] = useState('');
  const [buyerQuantityValue, setBuyerQuantityValue] = useState('');
  const [upToBuyValue, setUpToBuyValue] = useState(null);
  const [productName, setProductName] = useState('');
  const [productQuantity, setProductQuantity] = useState('');

  const [urlBannerImage, setUrlBannerImage] = useState('');
  let file;
  const [open, setOpen] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [stateSnack, setStateSnack] = useState({
    openSnack: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, openSnack } = stateSnack;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddressClickOpen = () => {
    setOpenAddress(true);
  };

  const handleAddressClose = (e) => {
    e.preventDefault();
    setOpenAddress(false);
  };

  const handleSnackClickOpen = (newStateSnack) => {
    setStateSnack({ openSnack: true, ...newStateSnack });
  };

  const handleSnackClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStateSnack({ ...stateSnack, openSnack: false });
  };

  useEffect(() => {
    let listDetail = listItemDetail[0];
    setListDetail(listDetail);
  }, [listItemDetail]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      user
        ? setUserLogged(user.uid)
        : setUserLogged(null)
    });
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value, files } = e.target;
    const list = [...items];
    if(files !== null) {
      list[index] = {...list[index], url: files[0]}
    }
    list[index][name] = value;
    setItems(list);
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...items];
    list.splice(index, 1);
    setItems(list);
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setItems([...items, { product: '', brand: '', quantity: 0, link: '', image: '', category: '', buyer: [], totalBuy: 0, upToBuy: 0 }]);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    if(listDetail.items <= 0) {
      setItems([{ product: '', brand: '', quantity: 0, link: '', image: '', category: '', buyer: [], totalBuy: 0, upToBuy: 0 }]);
    } else {
      setItems(listDetail.items)
    }
  }

  // const handleImage = (e) => {
  //   const { files } = e.target;
  //   if(files !== null) {
  //     file = files[0];
  //   }
  // }

  // const handleChangeBanner = (e) => {
  //   e.preventDefault();
  //   const storage = firebase.storage();
  //   const userId = firebase.auth().currentUser.uid;

  //   if(file !== undefined) {
  //     const uploadRef = storage.ref(`${userId}/banner/${file.name}`).put(file);
  //     uploadRef.on(
  //       "state_changed",
  //       snapshot => { },
  //       error => {
  //         console.log(error);
  //       },
  //       () => {
  //         storage
  //         .ref(`${userId}/banner`)
  //         .child(file.name)
  //         .getDownloadURL()
  //         .then(url => {
  //           setUrlBannerImage(url)
  //         })
  //       }
  //     )
  //   }
  //   console.log(urlBannerImage)
  //   console.log(listDetail.banner)
  // }

  // const handleUploadBanner = (e, id) => {
  //   e.preventDefault();
  //   handleChangeBanner(e);

  //   console.log(urlBannerImage)
  //   console.log(listDetail.banner)
  //   firebase
  //     .firestore()
  //     .collection("lists")
  //     .doc(id)
  //     .update({
  //       banner: urlBannerImage === '' ? listDetail.banner : urlBannerImage
  //     });
  // }

  // cons(e) => {
  //   e.preventDefault();
  //   const storage = firebase.storage();
  //   const userId = firebase.auth().currentUser.uid;

  //   const list = [...items];
  //   list.forEach(item => {
  //     if(item.url !== undefined) {
  //       const uploadRef = storage.ref(`${userId}/product/${item.url.name}`).put(item.url);
  //       uploadRef.on(
  //         "state_changed",
  //         snapshot => { },
  //         error => {
  //           console.log(error);
  //         },
  //         () => {
  //           if(item.url.name !== undefined) {
  //             storage
  //             .ref(`${userId}/product`)
  //             .child(item.url.name)
  //             .getDownloadURL()
  //             .then(url => {
  //               item.url = url
  //             })
  //           }
  //         }
  //       )
  //     }
  //   })
  // }

  const handleUpdateList = (e, id) => {
    e.preventDefault();
    firebase
      .firestore()
      .collection("lists")
      .doc(id)
      .update({
        // banner: urlBannerImage === '' ? listDetail.banner : urlBannerImage,
        title: titleValue === '' ? listDetail.title : titleValue,
        description: descriptionValue === '' ? listDetail.description : descriptionValue,
        date: dateValue === '' ? listDetail.date : dateValue,
        time: timeValue === '' ? listDetail.time : timeValue,
        address: addressValue === '' ? listDetail.address : addressValue,
        arriveAddress: arriveAddressValue === '' ? listDetail.arriveAddress : arriveAddressValue,
        callLink: callLinkValue === '' ? listDetail.callLink : callLinkValue,
        items: listDetail.items === [] ? listDetail.items : items
      });
  }

  const handleItemClicked = (e, item, index) => {
    e.preventDefault();
    setSelectedIndex(index);
    setItems(listDetail.items);

    let upToBuyQuantity = Number(item.upToBuy)
    setUpToBuyValue(upToBuyQuantity);

    setProductName(item.product)
    setProductQuantity(item.upToBuy)
  }

  useEffect(() => {
    return upToBuyValue && selectedIndex && productName && productQuantity && items && openSnack && buyerValue;
  }, [selectedIndex, upToBuyValue, items, productName, productQuantity, openSnack, buyerValue])

  const handleAddress = (e, id) => {
    e.preventDefault();
    let showAddressList = [...listDetail.showAddress];
    showAddressList.push(buyerShowAddressValue);

    const username = buyerShowAddressValue;
    localStorage.setItem('userNotLogged', username);

    firebase
      .firestore()
      .collection("lists")
      .doc(id)
      .update({
        showAddress: showAddressList
    });
  }

  const handleBuyerData = (e, id) => {
    e.preventDefault();

    let buyer = {name: buyerValue, quantityBuy: buyerQuantityValue};
    const list = [...listDetail.items];
    list[selectedIndex].buyer.push(buyer);

    let buyQuantity = items[selectedIndex].buyer.map(buyer => Number(buyer.quantityBuy));
    let totalQuantity = buyQuantity.reduce((a, b) => Number(a) + Number(b));

    list[selectedIndex].totalBuy = totalQuantity;
    list[selectedIndex].upToBuy = list[selectedIndex].quantity - totalQuantity;
    setItems(list);

    firebase
      .firestore()
      .collection("lists")
      .doc(id)
      .update({
        items: items
      });
  }
  
  return (
  <>
    {listDetail !== undefined &&
    <>
      <Image src={listDetail.banner} className={classes.banner}/>
      <form class={isInputBannerActive ? "active" : "hidden"}>
        {/* <Input label="Imagem do banner" name="image" type="file" value={file} onChange={(e) => {handleImage(e); handleChangeBanner(e)}}/> */}
      </form>
      
      {userLogged &&
      <>
        <Box display={!isInputBannerActive ? "block" : "none"}>
          <Button className={classes.button} text="Editar banner" onClick={() => setIsInputBannerActive(true)} />
        </Box>
        <Box display={isInputBannerActive ? "block" : "none"}>
          <Button className={classes.button} text="Cancelar edição do banner" onClick={() => setIsInputBannerActive(false)}/>
          {/* <Button className={classes.button} text="Upload" onClick={(e) => {handleUploadBanner(e, listDetail.id); setIsInputBannerActive(false)}}/> */}
        </Box>
      </>
      }

      <section class={!isInputActive ? "active" : "hidden"}>
        {/* <h3>Lista de {listDetail.userName}</h3> */}
        {/* <h2>{listDetail.title}</h2> */}
        <article dangerouslySetInnerHTML={{ __html: listDetail.description }}></article>
        {/* <Box>
          <h3>Endereço do evento</h3>
          <p>{listDetail.address}</p>
        </Box> */}
        {(!userLogged && isShowAddress) || localStorage.getItem('userNotLogged') !== null
          ? <Box>
              <h3>Endereço de entrega</h3>
              <address dangerouslySetInnerHTML={{ __html: listDetail.arriveAddress }}></address>
            </Box>
          : <Button className={classes.button} text="Mostrar endereço" color="primary" variant="contained" onClick={handleAddressClickOpen}/>
        }
        {userLogged &&
          <Box>
            <h3>Endereço de entrega</h3>
            <address dangerouslySetInnerHTML={{ __html: listDetail.arriveAddress }}></address>
          </Box>
        }
        <Box>
            <h3>Data</h3>
            <Box>
              <p>{new Date(listDetail.date +" 00:00:00").toLocaleDateString('pt-BR', {year: 'numeric', month: 'long', day: 'numeric'})} | {listDetail.time}</p>
              {listDetail.date !== undefined &&
                <Button className={classes.button} text="Adicionar na agenda" endIcon={<EventIcon />} color="primary" variant="outlined" href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${listDetail.title}&details=${listDetail.description}<p><strong>Link%20do%20evento%20</strong></p><p></p>${listDetail.callLink !== '' ? listDetail.callLink : 'Em%20breve'}</p>&dates=${(listDetail.date).replaceAll('-', '')}T180000Z/${(listDetail.date).replaceAll('-', '')}T210000Z&pli=1`} target="_blank"/>
              }
            </Box>
        </Box>
        <Box>
          <h3>Link da call/live</h3>
          {listDetail.callLink !== ''
            ? <Button href={listDetail.callLink} target="_blank" className={classes.button} text="Acesse o evento" endIcon={<YouTubeIcon />} color="primary" variant="outlined" />
            : <p>Em breve!</p>
          }
        </Box>
      </section>
      <form class={isInputActive ? "active" : "hidden"}>
        <Input label="Título do evento" type="text" value={titleValue} onChange={(e) => setTitleValue(e.target.value)}/>
        <textarea label="Descrição do evento" type="text" value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value)}></textarea>
        <Input label="Data do evento" type="date" value={dateValue} onChange={(e) => setDateValue(e.target.value)}/>
        <Input label=">Hora do evento" type="time" value={timeValue} onChange={(e) => setTimeValue(e.target.value)}/>
        <Input label="Endereço do evento" type="text" value={addressValue} onChange={(e) => setAddressValue(e.target.value)}/>
        <Input label="Endereço de entregas" type="text" value={arriveAddressValue} onChange={(e) => setArriveAddressValue(e.target.value)}/>
        <Input label="Link da call" type="text" value={callLinkValue} onChange={(e) => setCallLinkValue(e.target.value)}/>
      </form>

      {userLogged &&
      <>
        <Box display={!isInputActive ? "block" : "none"}>
          <Button className={classes.button} 
            text="Editar descrição" 
            onClick={() => {
              setIsInputActive(true); 
              setTitleValue(listDetail.title);
              setDescriptionValue(listDetail.description);
              setDateValue(listDetail.date);
              setTimeValue(listDetail.time);
              setAddressValue(listDetail.address);
              setArriveAddressValue(listDetail.arriveAddress);
              setCallLinkValue(listDetail.callLink);
            }}
          />
        </Box>
        <Box display={isInputActive ? "block" : "none"}>
          <Button className={classes.button} text="Cancelar edição da descrição" onClick={() => setIsInputActive(false)}/>
          <Button className={classes.button} text="Salvar" onClick={(e) => {handleUpdateList(e, listDetail.id); setIsInputActive(false); setIsAddItem(false)}} />
        </Box>
      </>
      }

      {!userLogged &&
      <>
        <Dialog fullScreen open={openAddress} onClose={handleAddressClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBarModal}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleAddressClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Ops!
              </Typography>
              <Button className={classes.button} autoFocus color="inherit" onClick={handleAddressClose}>
                fechar
              </Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xs">
            <Typography variant="body2" color="primary" className={classes.text}>Para você poder ver nosso endereço, precisamos saber quem você é antes!</Typography>
            <form onSubmit={(e) => {setIsShowAddress(true); handleAddressClose(e); handleAddress(e, listDetail.id)}} >
              <Input 
                label="Quem é você"
                name="name"
                type="text"
                value={buyerShowAddressValue}
                required
                onChange={(e) => setBuyerShowAddressValue(e.target.value)}
              />
              <Box className={classes.buttonGroup}>
                <Button className={classes.button} text="Cancelar" onClick={(e) => {handleAddressClose(e); setBuyerShowAddressValue('')}}/>
                <Button type="submit" className={classes.button} text="Mostrar endereço" color="primary" variant="contained" />
              </Box>
            </form>
          </Container>
        </Dialog>
      </>
      }
      
      {/* buyer data/message */}
      {!userLogged && isBuyItem &&
      <>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBarModal}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Vou comprar {(productName).toLowerCase()}
              </Typography>
              <Button className={classes.button} autoFocus color="inherit" onClick={handleClose}>
                salvar
              </Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xs">
            <form onSubmit={(e) => {handleBuyerData(e, listDetail.id); handleSnackClickOpen({ vertical: 'top', horizontal: 'center' }); handleClose(e);}} >
              <Input 
                label="Quem é você"
                name="name"
                type="text"
                value={buyerValue}
                required
                onChange={(e) => setBuyerValue(e.target.value)}
              />
              <Input 
                label="Quantidade"
                name="quantityBuy"
                type="number"
                value={buyerQuantityValue}
                required
                onChange={(e) => setBuyerQuantityValue(e.target.value)}
              />
              <Box className={classes.text}>
                <Typography variant="body2" color="primary">* quantidade disponível: {productQuantity}</Typography>
                <Typography variant="body2" color="primary">** estamos usando essa lista para controle. Por favor, só confirme se for comprar.</Typography>
              </Box>

              <Box className={classes.buttonGroup}>
                <Button className={classes.button} text="Cancelar" onClick={(e) => {handleClose(e); setBuyerValue(''); setBuyerQuantityValue('')}}/>
                <Button type="submit" className={classes.button} disabled={buyerQuantityValue <= upToBuyValue && buyerQuantityValue > 0 ? !isUpToBuy : isUpToBuy} text="Confirmar compra" color="primary" variant="contained" />
              </Box>
            </form>
          </Container>
        </Dialog>

        <Snackbar open={openSnack} autoHideDuration={2500} onClose={() => {handleSnackClose(); setBuyerValue(''); setBuyerQuantityValue('');}} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
          <Alert onClose={() => {handleSnackClose(); setBuyerValue(''); setBuyerQuantityValue('');}} severity="success">
            Obrigado {buyerValue}!
          </Alert>
        </Snackbar>
      </>
      }

      { listDetail.items !== undefined &&
      <>
      <List display={!isInputProductActive ? "block" : "none"}>
      {listDetail.items.map((item, index) => {
        let secondary = `quantidade: ${item.quantity} | marca: ${item.brand}`;
        return (<>
          <ListItem key={index} className={classes.listItem} >
            <ListItemAvatar>
              <a href={item.link} target="_blank">
                <Image src={item.url} className={classes.listImage}/>
              </a>
            </ListItemAvatar>
            <Box>
                <ListItemText primary={item.product} secondary={secondary} />
              {!userLogged &&
              <Box className={classes.buttonGroup}>
                <Button href={item.link} target="_blank" className={classes.button} text={'Link'}  color="primary" variant="outlined" />   
                <Button className={classes.button} disabled={item.quantity > item.totalBuy ? !isUpToBuy : isUpToBuy} text={item.quantity > item.totalBuy ? "Comprar" : "Comprado"} endIcon={item.quantity > item.totalBuy ? <AddShoppingCartIcon /> : <CheckIcon />} color="primary" variant="contained" onClick={(e) => {setIsBuyItem(true); handleItemClicked(e, item, index); handleClickOpen()}}/>   
              </Box>
              }
            </Box>
          </ListItem>
          <Divider  className={classes.listDivider}  variant="middle" />
        </>)}
        )}
      </List>
        {isInputProductActive &&
        <>
          {items.map((item, index) => {
            return (
            <div key={index}>
              <fieldset>
              <Input 
                  label="Imagem do produto"
                  name="image"
                  type="file"
                  accept="image/jpeg"
                  onChange={(e) => {handleInputChange(e, index)}}
                />
                <Input 
                  label="Produto"
                  name="product"
                  type="text"
                  value={item.product}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                />
                <Input 
                  label="Quantidade"
                  name="quantity"
                  type="number"
                  value={item.quantity}
                  required
                  onChange={(e) => handleInputChange(e, index)}
                />
                <Input 
                  label="Link"
                  name="link"
                  type="url"
                  value={item.link}
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onChange={(e) => handleInputChange(e, index)}
                />
                {items.length !== 0 &&
                  <DeleteOutlineOutlinedIcon alt="Remover item da lista" onClick={(e) => handleRemoveClick(e, index)}/>  
                } 
              </fieldset>
              {items.length - 1 === index &&
                  <AppBar position="fixed" color="primary" display={!isAddItem ? "block" : "none"} className={classes.appBar}>
                    <Toolbar>
                      {isInputProductActive &&
                      <Fab size="medium" color="secondary" aria-label="Cancelar edição" className={classes.closeButton} onClick={() => setIsInputProductActive(false)}>
                        <CloseIcon />
                      </Fab>
                      }
                      <Fab color="primary" aria-label="Novo item" className={classes.addButton} onClick={(e) => handleAddClick(e)}>
                        <AddIcon />
                      </Fab>
                      {userLogged &&
                      <Fab size="medium" color="secondary" aria-label="Salvar itens" className={classes.saveButton} onClick={(e) => {handleUpdateList(e, listDetail.id); setIsInputActive(false); setIsAddItem(false)}}>
                        <SaveIcon />
                      </Fab>
                    } 
                    </Toolbar>
                  </AppBar>
                }
            </div>
            )
          })
          }
        </>
        }

        {userLogged &&
        <>
          <Box display={!isInputProductActive ? "block" : "none"}>
            <Button className={classes.button} text={listDetail.items.length <= 0 ? "Adicionar itens" : "Editar itens"} onClick={(e) => {setIsInputProductActive(true); handleEditClick(e)}}/>
          </Box>
        </>
        }
      </>
      } 
    
    </>
    }
  </>
  );
}

export default ListDetail;