import { createMuiTheme } from '@material-ui/core/styles';

export const brands = createMuiTheme({
  palette: {
    primary: {
      main: "#3b5998",
    },
    secondary: {
      main: "#dd4b39",
    }
  },
});

export const dark = createMuiTheme({
  palette: {
    primary: {
      main: "#401d90",
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  }
});
